<template>
  <div class="sidebar-horizontal">
    <b-navbar toggleable="md" type="dark" class="bg-blue-gradient">
      <b-container>
        <div class="nav-header">
          <b-navbar-brand to="/dashboard" class="brand">{{ $config.name }}</b-navbar-brand>
        </div>

        <ul v-if="nav" class="nav navbar-nav">
          <li class="sidebar-toggle dropdown"><a href="javascript:void(0)" @click="toggleNav" class="ripple pl-2 pr-0"><i class="fal fa-bars list-icon fs-20"></i></a></li>
        </ul>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown title="Notifications" toggle-class="ripple" menu-class="dropdown-card" class="d-none d-md-inline-flex" right>
            <template v-slot:button-content><i class="fal fa-bell list-icon p-2"></i><span v-if="notifications.length" class="button-pulse bg-danger notifications"></span></template>
            <div class="card">
              <header class="card-header d-flex justify-content-between">
                <i class="fal fa-bell fa-fw color-primary" aria-hidden="true"></i>
                <span class="heading-font-family flex-1 text-center fw-400">Notifications</span>
              </header>
              <ul class="card-body list-unstyled dropdown-list-group">
                <li v-if="!notifications.length" class="text-center small py-3">No messages</li>
                <li class="py-2" v-for="m in groupNotifications" :key="m.id"><router-link :to="m.url">
                  <div><i class="fal fa-fw" :class="[ m.priorityIcon, m.priorityColor ]"></i> <span class="text-muted small">{{ m.priorityText }}</span></div>
                  <div class="mt-2" v-if="m.title"><strong>{{ m.title }}</strong></div>
                  <div class="mt-2">{{ m.description }}</div>
                  <div class="mt-2 text-muted small" v-if="m.project"><span v-if="$auth.profile.site_admin">{{ m.project.group.name }} / </span>{{ m.project.name }}</div>
                  <div class="mb-1"></div>
                </router-link></li>
              </ul>
            </div>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown title="Create New ..." toggle-class="ripple" menu-class="dropdown-card w-dropdown-card-small" class="d-none d-md-inline-flex" right v-if="$auth.profile.site_admin">
            <template v-slot:button-content><i class="fal fa-plus list-icon"></i></template>
            <div class="card">
              <header class="card-header d-flex justify-content-between">
                <i class="fal fa-plus fa-fw color-primary" aria-hidden="true"></i>
                <span class="heading-font-family flex-1 text-center fw-400">Create New ...</span>
              </header>
              <ul class="card-body list-unstyled dropdown-list">
                <li><router-link to="/groups/new"><span class="align-middle">Group</span></router-link></li>
                <li><router-link to="/projects/new"><span class="align-middle">Project</span></router-link></li>
              </ul>
            </div>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown toggle-class="dropdown-toggle-user ripple pl-4" menu-class="dropdown-card dropdown-card-profile" right>
            <template v-slot:button-content>
              <span class="avatar thumb-xs2"><img :src="$auth.profile.avatar" class="rounded-circle" alt=""><i class="fal fa-chevron-down list-icon"></i></span>
            </template>
            <div class="card">
              <div class="card-body py-0">
                <div class="bg-light text-dark p-3 rounded-top">
                  Signed in as <span class="font-weight-bold">{{ $auth.profile.username }}</span>
                </div>
              </div>
              <ul class="list-unstyled card-body">
                <h6 v-if="$auth.profile.site_admin" class="dropdown-header">Admin</h6>
                <li v-if="$auth.profile.site_admin"><router-link to="/admin/dashboard"><span class="align-middle">Dashboard</span></router-link></li>
                <li v-if="$auth.profile.site_admin"><router-link to="/admin/accounting"><span class="align-middle">Accounting</span></router-link></li>
                <li v-if="$auth.profile.site_admin"><router-link to="/admin/accounting/reports/income"><span class="align-middle">Income Statement</span></router-link></li>
                <div v-if="$auth.profile.site_admin" class="dropdown-divider"></div>
                <li><router-link to="/profile"><span class="align-middle">Profile</span></router-link></li>
                <li><router-link to="/profile/password/edit"><span class="align-middle">Change Password</span></router-link></li>
                <li><router-link to="/profile/2fa"><span class="align-middle">Two-Factor Authentication</span></router-link></li>
                <li v-if="$auth.profile.enable_billing"><router-link to="/profile/billing"><span class="align-middle">Billing &amp; Payments</span></router-link></li>
                <li v-if="$auth.profile.enable_billing"><router-link to="/profile/issues"><span class="align-middle">Issue Activity</span></router-link></li>
                <!-- <div class="dropdown-divider"></div> -->
                <li><router-link to="/logout"><span class="align-middle">Log Out</span></router-link></li>
              </ul>
            </div>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-container>
    </b-navbar>

    <div v-if="nav === 'project'" class="content-wrapper">
      <aside class="site-sidebar clearfix rounded" :class="{ 'd-block': showNav }">
        <nav class="sidebar-nav">
          <div class="container">
            <ul class="nav in side-menu">
              <li :class="{ active: active === 'overview' }"><router-link :to="`/${group}/${project}`"><i class="list-icon fal fa-fw fa-tasks-alt fa-rotate-90"></i>Overview</router-link></li>
              <li :class="{ active: active === 'issues' }"><router-link :to="`/${group}/${project}/issues`"><i class="list-icon fal fa-fw fa-exclamation-circle"></i>Issues</router-link></li>
              <li :class="{ active: active === 'labels' }"><router-link :to="`/${group}/${project}/labels`"><i class="list-icon fal fa-fw fa-tags"></i>Labels</router-link></li>
              <li :class="{ active: active === 'files' }" v-if="flags.enable_files || $auth.profile.site_admin"><router-link :to="`/${group}/${project}/files`"><i class="list-icon fal fa-fw fa-code"></i>Files</router-link></li>
              <li :class="{ active: active === 'credentials' }" v-if="flags.enable_credentials || $auth.profile.site_admin"><router-link :to="`/${group}/${project}/credentials`"><i class="list-icon fal fa-fw fa-key"></i>Credentials</router-link></li>
              <li :class="{ active: active === 'members' }"><router-link :to="`/${group}/${project}/members`"><i class="list-icon fal fa-fw fa-users"></i>Team</router-link></li>
              <li class="ml-md-auto pr-0" :class="{ active: active === 'settings' }" v-if="$auth.profile.site_admin"><router-link :to="`/${group}/${project}/edit`"><i class="list-icon fal fa-fw fa-cog"></i>Settings</router-link></li>
            </ul>
          </div>
        </nav>
      </aside>
    </div>

    <div v-if="nav === 'profile'" class="content-wrapper">
      <aside class="site-sidebar clearfix rounded" :class="{ 'd-block': showNav }">
        <nav class="sidebar-nav">
          <div class="container">
            <ul class="nav in side-menu">
              <li :class="{ active: active === 'profile' }"><router-link to="/profile"><i class="list-icon fal fa-fw fa-user"></i>Profile</router-link></li>
              <li :class="{ active: active === 'password' }"><router-link to="/profile/password/edit"><i class="list-icon fal fa-fw fa-lock-alt"></i>Password</router-link></li>
              <li :class="{ active: active === '2fa' }"><router-link to="/profile/2fa"><i class="list-icon fal fa-fw fa-shield-alt"></i>Two-Factor Authentication</router-link></li>
              <li :class="{ active: active === 'billing' }" v-if="$auth.profile.enable_billing"><router-link to="/profile/billing"><i class="list-icon fal fa-fw fa-credit-card"></i>Billing & Payments</router-link></li>
              <li :class="{ active: active === 'issues' }" v-if="$auth.profile.enable_billing"><router-link to="/profile/issues"><i class="list-icon fal fa-fw fa-clipboard-list"></i>Issue Activity</router-link></li>
            </ul>
          </div>
        </nav>
      </aside>
    </div>

    <div v-if="nav === 'help'" class="content-wrapper">
      <aside class="site-sidebar clearfix rounded" :class="{ 'd-block': showNav }">
        <nav class="sidebar-nav">
          <div class="container">
            <ul class="nav in side-menu">
              <li :class="{ active: active === 'termsofservice' }"><router-link to="/help/termsofservice"><i class="list-icon fal fa-fw fa-file-contract"></i>Terms of Service</router-link></li>
              <li :class="{ active: active === 'privacypolicy' }"><router-link to="/help/privacypolicy"><i class="list-icon fal fa-fw fa-file-contract"></i>Privacy Policy</router-link></li>
            </ul>
          </div>
        </nav>
      </aside>
    </div>

    <div v-if="nav === 'blog'" class="content-wrapper">
      <aside class="site-sidebar clearfix rounded" :class="{ 'd-block': showNav }">
        <nav class="sidebar-nav">
          <div class="container">
            <ul class="nav in side-menu justify-content-between">
              <li :class="{ active: active === 'home' }"><router-link to="/blog"><i class="list-icon fal fa-fw fa-home"></i>Blog Home</router-link></li>
              <li :class="{ active: active === 'mobile' }"><router-link to="/blog/tag/mobile"><i class="list-icon fal fa-fw fa-mobile"></i>Mobile</router-link></li>
              <li :class="{ active: active === 'performance' }"><router-link to="/blog/tag/performance"><i class="list-icon fal fa-fw fa-rocket-launch"></i>Performance</router-link></li>
              <li :class="{ active: active === 'security' }"><router-link to="/blog/tag/security"><i class="list-icon fal fa-fw fa-shield-alt"></i>Security</router-link></li>
              <li :class="{ active: active === 'ui-ux' }"><router-link to="/blog/tag/ui-ux"><i class="list-icon fal fa-fw fa-phone-laptop"></i>UI/UX</router-link></li>
            </ul>
          </div>
        </nav>
      </aside>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { LayoutPlugin, NavbarPlugin } from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(NavbarPlugin);

export default {
  props: {
    nav: String,
    active: String,
    group: String,
    project: String,
  },
  data() {
    return {
      showNav: false,
      notificationsUpdateTimer: undefined,
      notifications: [],
      projects: [],
      flags: {},
    };
  },
  computed: {
    groupNotifications() {
      const m = [];
      for (let i = 0; i < this.notifications.length; i += 1) {
        let inList = false;
        for (let k = 0; k < m.length; k += 1) {
          if (this.notifications[i].url === m[k].url && this.notifications[i].title === m[k].title && this.notifications[i].description === m[k].description) {
            inList = true;
            break;
          }
        }
        if (!inList) {
          m.push({ ...this.notifications[i] });
        }
      }
      for (let i = 0; i < m.length; i += 1) {
        // add random string to end of any urls that match current path so that page will reload when notification is clicked
        if (m[i].url === this.$route.path) {
          m[i].url = `${m[i].url}?u=${Math.random().toString(36).substring(2, 10)}`;
        }
        // add project description
        m[i].project = this.getProject(m[i].url);
        // add priority text, icon, color
        switch (m[i].priority) {
          case 'warning':
            m[i].priorityText = 'Warning';
            m[i].priorityColor = 'text-warning';
            m[i].priorityIcon = 'fa-exclamation-triangle';
            m[i].priorityOrder = 1;
            break;
          case 'info':
          default:
            m[i].priorityText = 'Information';
            m[i].priorityColor = 'text-primary';
            m[i].priorityIcon = 'fa-info-circle';
            m[i].priorityOrder = 0;
            break;
        }
      }
      // sort by priority
      m.sort((a, b) => b.priorityOrder - a.priorityOrder);
      return m;
    },
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
    updateNotifications() {
      this.$api.get('/profile/notifications')
        .then((res) => {
          this.notifications = res.data;
          // dismiss notifications with current path
          for (let i = this.notifications.length - 1; i >= 0; i -= 1) {
            if (this.notifications[i].url === this.$route.path) {
              this.$api.delete(`/profile/notifications/${this.notifications[i].id}`);
              this.notifications.splice(i, 1);
            }
          }
        })
        .catch(() => {});
    },
    getProject(url) {
      const words = url.split('/');
      if (url[0] === '/' && url[1] !== '/' && words.length >= 3) {
        for (let i = 0; i < this.projects.length; i += 1) {
          if (words[1] === this.projects[i].group.path && words[2] === this.projects[i].path) {
            return this.projects[i];
          }
        }
      }
      return undefined;
    },
  },
  async mounted() {
    try {
      this.projects = (await this.$cache.api.get('/projects')).data;
      if (this.nav === 'project') {
        // set title
        const p = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
        document.title = `${p.group.name} / ${p.name} - ${this.$config.pretty_name}`;
        if (this.$route.meta.title) {
          document.title = `${this.$route.meta.title} - ${document.title}`;
        }
        // set user flags
        const m = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`)).data;
        for (let i = 0; i < m.length; i += 1) {
          if (m[i].id === this.$auth.profile.id) {
            this.flags.enable_files = m[i].enable_files;
            this.flags.enable_credentials = m[i].enable_credentials;
            break;
          }
        }
        // update last accessed project
        localStorage.setItem('group', this.$route.params.group);
        localStorage.setItem('project', this.$route.params.project);
      }
      this.updateNotifications();
      this.notificationsUpdateTimer = setInterval(this.updateNotifications, 60000);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  beforeDestroy() {
    clearInterval(this.notificationsUpdateTimer);
  },
};
</script>
